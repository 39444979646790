"use strict";

import {
  getFontSizeByTarget,
  getScaledValue,
  getStyleValueByNumber,
  setClassToIconByLight,
} from "./_common";
import { FONT_COLOR, NAMES } from "./_const";
import { getContentWidthOfPage } from "./resize";
import { WRP_TMBS_GAP, WRP_TMBS_PADDING, getScaleBySideMenu } from "./sideMenu";
import { setClassByBorder, setClassByHoverLight } from "./sizeTable";
import { createSvgOfMagnify } from "./svg";

const URL_TMB_NOTHING = `./images/bikes/_nothing_1_tmb.jpg`;
const URL_IMG_NOTHING = `./images/bikes/_nothing_1.jpg`;

const setFontSizeToTmb = (t) => {
  const textLen0 = t.textContent.length;
  const textLen1 = textLen0 < 10 ? 10 : textLen0; // minimum size
  const fs = getFontSizeByTarget(textLen1, t);
  t.style.fontSize = `${fs}px`;
};

export const TMB = {
  STYLE: {
    WIDTH: 200,
    HEIGHT: 200,
    MARGIN: 6,
    BORDER_RADIUS: 4,
  },
};

export const isValidUrl = async (url) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      resolve(true);
    };
    img.onerror = () => {
      resolve(false);
    };
  });
};

export function createTmbImg(urlType, mkr, mdl, years, year, color) {
  return new Promise((resolve) => {
    const img = new Image();
    const urls = getUrl();

    // img読み込みしなくても速度同じ
    // if (urlType === "err") {
    //   const tmbColor = createTmbColor();
    //   resolve(tmbColor);
    // } else if (urlType === "try") {
    img.src = urls.tmb;
    img.alt = "tmb";
    img.onerror = () => {
      resolve(null);
    };
    img.onload = () => {
      const tmbColor = createTmbColor();
      resolve(tmbColor);
    };
    // }

    function getUrl() {
      if (urlType === "try") {
        return {
          tmb: `./images/bikes/${mkr}_${mdl}_${year}_${color}_tmb.jpg`,
          img: `./images/bikes/${mkr}_${mdl}_${year}_${color}.jpg`,
        };
      } else if (urlType === "err") {
        return { tmb: URL_TMB_NOTHING, img: URL_IMG_NOTHING };
      }
    }
    function createTmbColor() {
      const div0 = document.createElement("div");
      div0.dataset.class = "tmbColor";
      div0.dataset.mkr = mkr;
      div0.dataset.mdl = mdl;
      div0.dataset.years = years;
      div0.dataset.year = year;
      div0.dataset.color = color;
      div0.dataset.urlTmb = urls.tmb;
      div0.dataset.urlImg = urls.img;
      div0.classList.add("relative");
      setClassByHoverLight(div0);
      setClassByBorder(div0);
      div0.classList.add("overflow-hidden");

      const div1 = document.createElement("div");
      div1.dataset.class = "wrpImg";
      div1.classList.add("grid");
      div1.classList.add("place-items-center");
      div1.classList.add("bg-white");
      div1.style.height = "100%";

      const h = document.createElement("h6");
      h.dataset.class = "tmbColorH";
      h.classList.add("bg-white/80");
      // h.classList.add("leading-none");
      h.classList.add("content-center");
      h.classList.add("text-center");
      h.classList.add("absolute");
      h.style.color = FONT_COLOR;
      h.textContent = year;

      const div2 = document.createElement("div");
      div0.appendChild(h);
      // if (urlType === "try") div2.appendChild(img);
      div2.appendChild(img);
      div1.appendChild(div2);
      div0.appendChild(div1);
      return div0;
    }
  });
}

export function createFullImgByBg(url) {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = url;
    img.setAttribute("src", url);
    img.setAttribute("alt", "fullImg");
    img.onerror = () => {
      resolve(null);
    };
    img.onload = () => {
      img.id = "imgFull";
      img.dataset.width = img.width;
      img.dataset.height = img.height;
      resolve(img);
    };
  });
}

export function setStyleToTmbImgs() {
  const smYearWidth = getStyleValueByNumber({ id: "smYear", item: "width" });
  const smYearP = getStyleValueByNumber({ id: "smYear", item: "padding" });

  const scale = getScaleBySideMenu();
  const tmbsP = WRP_TMBS_PADDING * scale;
  const tmbsG = WRP_TMBS_GAP * scale;
  const width = smYearWidth - smYearP * 2 - tmbsP * 2;

  const val0 = 200;

  const numberOfAlign = width > 450 ? Math.floor(width / (val0 + tmbsG)) : 2;
  const remainder = width - (val0 + tmbsG) * numberOfAlign;
  const adjust = Math.floor(remainder / numberOfAlign);
  const val = val0 + adjust;

  document.querySelectorAll(`div[data-class='tmbColor']`).forEach((t) => {
    t.style.width = `${val}px`;
    t.style.height = `${val * 0.85 * 0.8}px`;
  });

  document.querySelectorAll(`h6[data-class='tmbColorH']`).forEach((t) => {
    t.style.width = `${val * 0.25}px`;
    t.style.height = `${val * 0.13}px`;
    t.style.top = `${val * 0.01}px`;
    t.style.left = `${val * 0.01}px`;
    // t.style.bottom = `${val * 0.02}px`;
    // t.style.right = `${val * 0.02}px`;
    // t.style.paddingLeft = `${cWidth * 0.005}px`;
    // t.style.paddingRight = `${cWidth * 0.005}px`;
    // setFontSizeToTmb(t);
    const textLen = 4;
    const fs = getFontSizeByTarget(textLen, t);
    t.style.fontSize = `${fs}px`;
    // h.style.fontSize = `${fs}px`;
  });
}

export function setStyleToFullImg() {
  const padding = getScaledValue(20);
  document.getElementById("imgsByYear").style.padding = `${padding}px`;
  const wrpFullImg = document.getElementById("wrpFullImg");
  wrpFullImg.style.marginTop = `${padding}px`;
  wrpFullImg.style.marginBottom = `${padding}px`;

  const img = document.getElementById("imgFull");
  if (!img) return;

  const cWidth = document.documentElement.clientWidth * 0.9;
  const cHeight = document.documentElement.clientHeight * 0.8;
  const iWidth = Number(img.dataset.width);
  const iHeight = Number(img.dataset.height) * 1.5;
  const scaleWidth = cWidth / iWidth;
  const scaleHeight = cHeight / iHeight;

  // scale lower value that means img is bigger than client
  // scale that is not match by getScaleByWGTT and getScaleByDispType
  const scale = (() => {
    // img width and height bigger than client
    if (scaleWidth < 1 && scaleHeight < 1) {
      // return scale by bigger value
      return scaleWidth < scaleHeight ? scaleWidth : scaleHeight;
    }
    if (scaleWidth < 1) {
      return scaleWidth;
    }
    if (scaleHeight < 1) {
      return scaleHeight;
    }
    // return 1;
    return scaleWidth < scaleHeight ? scaleWidth : scaleHeight;
  })();

  // img,videoのheightはcssでautoに設定されているので設定しない
  const imgWidth = (() => {
    const w = img.dataset.width * scale;
    if (w > img.dataset.width) {
      return img.dataset.width;
    } else {
      return w;
    }
  })();

  // hTagの幅を設定していないとテキストの折り返しが反映されない
  document.getElementById("wrpFullImg").style.width = `${imgWidth}px`;
  document.getElementById("btnDecideBike").style.width = `${imgWidth}px`;
  document.getElementById("hTagByFullImg").style.width = `${imgWidth}px`;
}

export function setStyleToContentBox() {
  const contentBox = document.getElementById("contentBox");
  const padding = getScaledValue(20);
  contentBox.style.padding = `${padding}px`;

  const hTagByContentBox = document.getElementById("hTagByContentBox");

  const page = document.querySelectorAll(
    `article[data-class='page'][data-active='true']`
  );
  const width = getContentWidthOfPage(page.id);
  const width2 = width * 0.8;
  if (width2 > 600) {
    hTagByContentBox.style.width = `600px`;
  } else if (width2 < 350) {
    hTagByContentBox.style.width = `350px`;
  } else {
    hTagByContentBox.style.width = `${width2}px`;
  }

  // hTagByContentBox.style.width = "";
}

export function setStyleToWrapperImgsByYear() {
  const wiy = document.getElementById("wrapperImgsByYear");
  // vh,vwスマホでだめ
  wiy.style.width = `${document.documentElement.clientWidth}px`;
  wiy.style.height = `${document.documentElement.clientHeight}px`;
}
