"use strict";
import { drawBikes } from "./bike";
import { BIKE_SCALE } from "./_const";
import {
  getSelectedInfoFromSMMM,
  getSelectedListOfYearBySmYear,
  setBikeDataToSideMenuMakerModel,
  setBikeDataToSideMenuYear,
} from "./sideMenu";
import { setBikeDataToSizeTable } from "./sizeTable";
import { getYearsByArray } from "./_common";

function getParameter(syntax) {
  setConnectTypeOfPhp(syntax);
  return {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(syntax),
  };

  function setConnectTypeOfPhp(syntax) {
    syntax.cnctType = getConnectTypeOfPhp();
    // return ();
  }
  function getConnectTypeOfPhp() {
    if (location.href.includes("https://www.com-geo.net")) {
      return "pro";
    } else {
      return "dev";
    }
  }
}

export function execGetBikeData(getType, mkrMdlYear) {
  console.log("setBikeDataToSelectOrTable");
  return new Promise((resolve) => {
    getBikeDataThenReturnPromise(resolve);
  });

  function getBikeDataThenReturnPromise(resolve) {
    const syntax = getSyntax();
    getBikeDataByPhp(syntax)
      .then((values) => {
        resolve(values);
      })
      .catch((err) => {
        console.log(err);
      });

    function getSyntax() {
      // phpへ処理に必要な変数を連想配列で渡す
      // ＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃＃要修正＞項目名を隠す
      const GEOMETRY_GRID_END_HEIGHT = 50; // （実寸）
      const FRAME_FORK_CURVE_POINT = 0.7;
      const DRAW_ANGLE_DIA = 250; // agl円弧の表示サイズ（直径の実寸）
      const result = {
        wording: { setType: "wording" },
        wordingAfter: { setType: "wordingAfter" },
        modelAll: { setType: "modelAll" },
        sizeAll: { setType: "sizeAll" },
        geometry: {
          setType: "geometry",
          mkrMdlYear,
          js: {
            scale: BIKE_SCALE,
            lineDia: GEOMETRY_GRID_END_HEIGHT,
            drawAglDia: DRAW_ANGLE_DIA,
            cLenFork: FRAME_FORK_CURVE_POINT,
          },
        },
      };
      return result[getType];
    }
  }
}

export async function getBikeDataByPhp(syntax) {
  console.log(`getBikeDataByPhp`);
  const PHP_URL_GET_BIKE_DATA = "php/getGeo.php";
  const prms = getParameter(syntax);
  const res = await fetch(PHP_URL_GET_BIKE_DATA, prms).catch((err) =>
    console.log(err)
  );
  const values = await res.json().catch((err) => console.log(err));
  return values;
}

export async function updateSelectedBikeCountOfPHP() {
  console.log(`updateSelectedBikeCountOfPHP`);
  const PHP_URL_UPDATE_SELECTED_BIKE_COUNT = "php/countUp.php";
  const syntax = getSyntaxOfUpDateCount();
  if (!syntax) return;
  const prms = getParameter(syntax);
  prms.mode = "cors";
  await fetch(PHP_URL_UPDATE_SELECTED_BIKE_COUNT, prms).catch((err) =>
    console.log(err)
  );

  function getSyntaxOfUpDateCount() {
    // modelYear選択時に実行
    const info = getSelectedInfoFromSMMM();
    const id = `${info.mkr}_${info.mdl}_${info.year}`;
    return {
      setType: "update",
      id,
    };
  }
}

export function extractModelsByModelAll(values, extType) {
  // mkr/mdlはnew Setで重複削除
  const models0 = values.map((t) => {
    const names = t.id.split("_");
    const mkr = names[0];
    const mdl = names[1];
    const year = getYearsByArray(t.releaseYear);
    return { mkr, mdl, year, releaseYear: t.releaseYear };
  });

  // 配列にして重複削除->配列から連想配列にしている
  if (extType === "mkr") {
    const ts0 = [...new Set(models0.map((t) => t.mkr))];
    return ts0.map((t) => ({ mkr: t }));
  } else if (extType === "mdl") {
    const ts0 = [...new Set(models0.map((t) => `${t.mkr}_${t.mdl}`))];
    return ts0.map((t) => {
      const ary = t.split("_");
      return { mkr: ary[0], mdl: ary[1] };
    });
  } else if (extType === "year") {
    return models0.map((t) => {
      return {
        mkr: t.mkr,
        mdl: t.mdl,
        year: t.year,
        releaseYear: t.releaseYear,
      };
    });
  }
}
