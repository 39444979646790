"use strict";
import {
  getEventTargetsByBetween,
  getYears,
  getYearsByArray,
  replaceName,
} from "./_common";
import { NAMES } from "./_const";
import {
  createFullImgByBg,
  createTmbImg,
  setStyleToFullImg,
  setStyleToTmbImgs,
} from "./img";
import { setStyleToSideMenu, setClassByWrpTmbs } from "./sideMenu";
import { getWordingName } from "./wording";

export function showImgsByYears(mkr, mdl) {
  return new Promise((resolve) => {
    // createListsByYears();
    setImgsToListYear(resolve);
    setStyleToSideMenu();
    setStyleToTmbImgs(); // execSetStyleToSideMenuのあとに実施 *sideMenuのstyleを利用
  });

  async function setImgsToListYear(resolve) {
    const listYears = document.querySelectorAll(
      `h3[data-class='yearH'][data-mkr='${mkr}'][data-mdl='${mdl}']`
    );
    for (let i = 0; i < listYears.length; i++) {
      const yearH = listYears[i];
      const yearsNum = getYearsByNum(yearH.dataset.releaseYear);
      const years = yearH.dataset.years;
      const div = document.createElement("div");
      div.dataset.class = "wrpTmbs";
      setClassByWrpTmbs(div);
      yearH.parentElement.insertBefore(div, yearH.parentElement.children[1]);
      for (let k = 0; k < yearsNum.length; k++) {
        const year = yearsNum[k];
        const mkr = yearH.dataset.mkr;
        const mdl = yearH.dataset.mdl;
        // 10色まで表示
        for (let j = 1; j < 11; j++) {
          const wrpImg1 = await createTmbImg("try", mkr, mdl, years, year, j);
          if (wrpImg1) {
            setWrpImg(div, wrpImg1, mkr, mdl, years, year, j);
            continue;
          }
          if (j > 1) continue; // noImgは各年式に1枚目のみ
          const wrpImg2 = await createTmbImg("err", mkr, mdl, years, year, j);
          if (wrpImg2) {
            setWrpImg(div, wrpImg2, mkr, mdl, years, year, j);
            continue;
          }
        }
      }
    }
    resolve();

    function getYearsByNum(years) {
      // 数値の場合は配列にして返す
      if (!isNaN(years)) return [years];
      return years.split(",");
    }
    function setWrpImg(div, wrpImg, mkr, mdl, years, year, color) {
      // wrpImg.dataset.class = "tmbColor";
      // wrpImg.dataset.mkr = mkr;
      // wrpImg.dataset.mdl = mdl;
      // wrpImg.dataset.years = years;
      // wrpImg.dataset.year = year;
      // wrpImg.dataset.color = color;
      // wrpImg.classList.add("relative");
      div.appendChild(wrpImg);
    }
  }
}

export async function showFullImgByGeometry(thImg) {
  // init
  const imgFull = document.getElementById("imgFull");
  if (imgFull) imgFull.remove();
  const title = document.getElementById("hTagByFullImg");
  title.textContent = "";
  document.getElementById("imgsByYear").style.display = "";
  document.getElementById("btnDecideBike").style.display = "none";
  document.getElementById("contentBox").style.display = "none";

  // set
  // const names = getNames();
  const mkr = thImg.dataset.mkr;
  const mdl = thImg.dataset.mdl;
  const years = thImg.dataset.years;

  // sourceは該当のジオメトリー表の最新のものを表示
  // 年度別のジオメトリー表はエビデンスとしておいておく
  const years2 = years.split("-");
  const url = `./images/bikes/${mkr}_${mdl}_${years2[1]}_1_geo.jpg`;

  const img = await createFullImgByBg(url);
  if (img) {
    document.getElementById("wrpFullImg").appendChild(img);
    setStyleToFullImg();
  }

  const mkr1 = getWordingName(mkr);
  const mdl1 = getWordingName(mdl);
  const years1 = getYears(years);
  title.textContent = `${mkr1}  ${mdl1}  ${years1}`;
  document.getElementById("wrapperImgsByYear").style.display = "";
}

export async function showFullImgByBike(target) {
  // init
  const imgFull = document.getElementById("imgFull");
  if (imgFull) imgFull.remove();
  const title = document.getElementById("hTagByFullImg");
  title.textContent = "";
  document.getElementById("imgsByYear").style.display = "";
  document.getElementById("btnDecideBike").style.display = "";
  document.getElementById("contentBox").style.display = "none";

  // set
  // target > wrpFullImg > imgDisp へ情報を引き継ぐ
  const wrpFullImg = document.getElementById("wrpFullImg");
  const mkr = target.dataset.mkr;
  const mdl = target.dataset.mdl;
  const years = target.dataset.years;
  const year = target.dataset.year;
  const color = target.dataset.color;
  const urlTmb = target.dataset.urlTmb;
  const urlImg = target.dataset.urlImg;

  wrpFullImg.dataset.mkr = mkr;
  wrpFullImg.dataset.mdl = mdl;
  wrpFullImg.dataset.years = years;
  wrpFullImg.dataset.year = year;
  wrpFullImg.dataset.color = color;
  wrpFullImg.dataset.urlTmb = urlTmb;
  wrpFullImg.dataset.urlImg = urlImg;

  const img = await createFullImgByBg(urlImg);

  if (img) {
    wrpFullImg.appendChild(img);
    setStyleToFullImg();
  }

  const mkr1 = getWordingName(mkr);
  const mdl1 = getWordingName(mdl);
  const year1 = getYears(years);
  title.textContent = `${mkr1}  ${mdl1}  ${year1} (${year})`;
  document.getElementById("wrapperImgsByYear").style.display = "";
}

export function hideImgsByYear(event) {
  // const t = getEventTargetsByBetween(event).find(
  //   (t) => t.id === "wrapperImgsByYear" || t.id === "imgsByYear"
  // );
  // const t = getEventTargetsByBetween(event).find(
  //   (t) => t.id === "wrapperImgsByYear" || t.id === "btnDecideBike"
  // );
  // if (!t) return;
  const target = getTarget();
  if (!target) return;
  document.getElementById("wrapperImgsByYear").style.display = "none";

  function getTarget() {
    const btnDecideBike = getEventTargetsByBetween(event).find(
      (t) => t.id === "btnDecideBike"
    );
    if (btnDecideBike) return btnDecideBike;

    const imgsByYear = getEventTargetsByBetween(event).find(
      (t) => t.id === "imgsByYear"
    );
    if (imgsByYear) return null;

    const contentBox = getEventTargetsByBetween(event).find(
      (t) => t.id === "contentBox"
    );
    if (contentBox) return null;

    const wrapperImgsByYear = getEventTargetsByBetween(event).find(
      (t) => t.id === "wrapperImgsByYear"
    );
    if (wrapperImgsByYear) return wrapperImgsByYear;
  }
}
