"use strict";
import {
  changeWindowByFlexOrBlock,
  getDispTypeByWGTT,
  getDispTypeLastByWGTT,
  getEventTargetsByBetween,
} from "./_common";
import { resize } from "./resize";
import { changeBikeSize } from "./changeBikeSize";
import {
  showSideMenu,
  onClickSideMenuMakerModel,
  onClickSideMenuYear,
  initModelUlToSmYear,
  setModelUlToSmYear,
  getSelectedListOfModelBySmMakerModel,
  onClickImgOfYears,
  onClickImgOfColors,
  onClickSmYearClose,
  onClickDecideBike,
  onBtnSmClose,
} from "./sideMenu";
import { onClickNaviIconByOpen, onClickNaviList } from "./navi";
import {
  onClickBase,
  onClickImgOfColorsByDisplay,
  onClickIndicator,
} from "./display";
import { onClickHighlight } from "./highlight";
import {
  hideImgsByYear,
  showFullImgBy,
  showFullImgByGeometry,
} from "./imgsByYear";
import { getThImg } from "./sizeTable";
import { changeCompare, compare } from "./compare";
import { getActivePage, isComparePage } from "./page";
import { setStyleToContentBox } from "./img";
import {
  setColorToBtnWording,
  setLanguageTypeToDataset,
  setWording,
  setWordingAfter,
} from "./wording";
import { showTextBy } from "./contentBox";
import { onClickOverlay } from "./overlay";

export function execSetEventToAll() {
  window.addEventListener("resize", resize, false);
  window.addEventListener("orientationchange", onRotationDisplay, false);

  document
    .getElementById("btnToComparePage")
    .addEventListener("click", onClickBtnToComparePage, false);
  document
    .getElementById("btnNaviOpen")
    .addEventListener("click", onClickNaviIconByOpen, false);
  document
    .getElementById("naviBlock")
    .addEventListener("click", onClickNaviList, false);
  document
    .getElementById("overlay")
    .addEventListener("click", onClickOverlay, false);
  document
    .getElementById("wrpPositionSize")
    .addEventListener("click", onClickPositionSize, false);
  document
    .getElementById("wrpImgDisp")
    .addEventListener("click", onClickImgOfColorsByDisplay, false);
  document
    .getElementById("btnCompare")
    .addEventListener("click", onClickCompare, false);
  document
    .getElementById("btnIndicator")
    .addEventListener("click", onClickIndicator, false);
  document
    .getElementById("btnBase")
    .addEventListener("click", onClickBase, false);
  document
    .getElementById("btnFullScreen")
    .addEventListener("click", onClickIconFullScreen, false);
  document
    .getElementById("btnWindowed")
    .addEventListener("click", onClickIconFullScreen, false);
  document
    .getElementById("btnShowSideMenu")
    .addEventListener("click", onclickBtnShowSideMenu, false);

  document
    .getElementById("wrapperImgsByYear")
    .addEventListener("click", hideImgsByYear, false);
  document
    .getElementById("wrapperValueLeft")
    .addEventListener("click", onClickHighlight, false);
  document
    .getElementById("wrapperValueRight")
    .addEventListener("click", onClickHighlight, false);
  document
    .getElementById("wrapperBikes")
    .addEventListener("click", onClickCompareTagOfTh, false);
  document
    .getElementById("btnTagOutLine")
    .addEventListener("click", onClickCompareTagOfDisp, false);
  document
    .getElementById("btnTagSolid")
    .addEventListener("click", onClickCompareTagOfDisp, false);
  document
    .getElementById("btnWordingToEng")
    .addEventListener("click", onClickWording, false);
  document
    .getElementById("btnWordingToJpn")
    .addEventListener("click", onClickWording, false);
  document
    .getElementById("btnWordingToEngNavi")
    .addEventListener("click", onClickWording, false);
  document
    .getElementById("btnWordingToJpnNavi")
    .addEventListener("click", onClickWording, false);

  document
    .getElementById("smMakerModel")
    .addEventListener("click", onClickSideMenuMakerModel, false);
  document
    .getElementById("smYear")
    .addEventListener("click", onClickImgOfColors, false);
  document
    .getElementById("btnSmYearClose")
    .addEventListener("click", onClickSmYearClose, false);
  document
    .getElementById("btnSmClose")
    .addEventListener("click", onBtnSmClose, false);
  document
    .getElementById("btnDecideBike")
    .addEventListener("click", onClickDecideBike, false);

  const st = document.getElementById("sizeTable");
  st.addEventListener("click", onClickHighlight, false);
  st.addEventListener("click", onClickSource, false);
  st.addEventListener("click", onClickSimilar, false);
  st.addEventListener("click", onClickSizeTable, false);
  st.addEventListener("click", onClickCompareTagOfTh, false); // td/thの追加削除を行うので最後に実施

  function onClickBtnToComparePage() {
    document
      .querySelector(`li[data-class='naviList'][data-page='pageCompare']`)
      .click();
    const dispType = getDispTypeByWGTT();
    if (dispType === "full") {
      changeWindowByFlexOrBlock("full");
      resize();
    }
    document.getElementById("btnShowSideMenu").click();
  }
  function onClickIconFullScreen(event) {
    if (!isComparePage()) return;
    const t = getEventTargetsByBetween(event).find(
      (t) => t.tagName.toLowerCase() === "button"
    );
    if (!t) return null;
    const dispType = getDispTypeByWGTT();
    const result = {
      block: "full",
      flex: "full",
      full: getDispTypeLastByWGTT(),
    };
    changeWindowByFlexOrBlock(result[dispType]);
    resize();
  }
  function onClickSource(event) {
    console.log("onClickSource");
    // check target
    const btn = getEventTargetsByBetween(event).find(
      (t) => t.dataset.class === "sourceBtn"
    );
    if (!btn) return;
    const td = btn.parentElement.parentElement;
    const thImg = getThImg(td.dataset.colIndex);
    if (!thImg) return;
    showFullImgByGeometry(thImg);
  }
  function onClickSimilar(event) {
    console.log("onClickSimilar");
    // check target
    const btn = getEventTargetsByBetween(event).find(
      (t) => t.dataset.class === "similarBtn"
    );
    if (!btn) return;
    const td = btn.parentElement.parentElement;
    const thImg = getThImg(td.dataset.colIndex);
    if (!thImg) return;
    const btn1 = document.querySelector(
      `td[data-class='similar'][data-col-index='${thImg.dataset.colIndex}'] button`
    );
    if (!btn1.dataset.similar3) return;
    setStyleToContentBox();
    showTextBy(thImg, "similar");
  }
  function onClickPositionSize(event) {
    const t = getEventTargetsByBetween(event).find(
      (t) => t.tagName.toLowerCase() === "button"
    );
    if (!t) return;
    const thImg = getThImg(t.dataset.colIndex);
    if (!thImg) return;
    changeBikeSize(thImg);
  }
  function onClickCompareTagOfTh(event) {
    const thCompare = getEventTargetsByBetween(event).find(
      (t) =>
        t.dataset.class === "btnTagOutLine" || t.dataset.class === "btnTagSolid"
      // t.dataset.class === "compare" && t.dataset.colIndex !== "header"
    );
    if (!thCompare) return null;
    const thImg = getThImg(thCompare.parentElement.dataset.colIndex);
    // const thImg = getThImg(thCompare.dataset.colIndex);
    if (!thImg) return;
    changeCompare(thImg);
  }
  function onClickCompareTagOfDisp(event) {
    const btnTag = getEventTargetsByBetween(event).find(
      (t) => t.id === "btnTagOutLine" || t.id === "btnTagSolid"
    );
    if (!btnTag) return null;

    const isCompare = document.getElementById("display").dataset.compareDisp;
    const thImg = document.querySelector(
      `th[data-class='img'][data-active='true'][data-compare='${isCompare}']`
    );
    if (!thImg) return null;
    changeCompare(thImg);
  }
  function onClickSizeTable(event) {
    const compareTag = getEventTargetsByBetween(event).find(
      (t) =>
        t.dataset.class === "btnTagOutLine" || t.dataset.class === "btnTagSolid"
    );
    if (compareTag) return;

    const t0 = getEventTargetsByBetween(event).find(
      (t) => t.dataset.class === "sourceBtn" || t.dataset.class === "similarBtn"
    );
    if (t0) return;

    const t = getEventTargetsByBetween(event).find(
      (t) =>
        t.tagName.toLowerCase() === "td" ||
        (t.tagName.toLowerCase() === "th" && t.scope === "col")
    );
    if (!t) return;

    const thImg = getThImg(t.dataset.colIndex);
    if (!thImg) return;

    changeBikeSize(thImg);
  }
  function onclickBtnShowSideMenu(event) {
    const sideMenu = document.getElementById("sideMenu");
    if (sideMenu.style.display !== "none") {
      // onClickOverlay(event);
      sideMenu.style.display = "none";
      document.getElementById("btnShowSideMenu").style.zIndex = "";
      document.getElementById("overlay").style.display = "none";
      return;
    }

    if (!isComparePage()) return;
    const t = getEventTargetsByBetween(event).find(
      (t) => t.tagName.toLowerCase() === "button"
    );
    if (!t) return null;

    showSideMenu(event);
    // document.getElementById("overlay").style.display = "";
    // const smDisp = document.getElementById("sideMenu").style.display;
    // if (smDisp === "none") {
    //   showSideMenu(event);
    // } else {
    //   onClickOverlay(event);
    // }
  }
  function onClickCompare(event) {
    if (!isComparePage()) return;
    const t = getEventTargetsByBetween(event).find(
      (t) => t.tagName.toLowerCase() === "button"
    );
    if (!t) return;

    compare();

    const spanToCompare = document.getElementById("spanToCompare");
    const spanToSize = document.getElementById("spanToSize");
    if (spanToCompare.style.display === "none") {
      spanToCompare.style.display = "";
      spanToSize.style.display = "none";
    } else {
      spanToCompare.style.display = "none";
      spanToSize.style.display = "";
    }
  }
  function onFocus(event) {
    initModelUlToSmYear();
    const t = getEventTargetsByBetween(event).find(
      (t) =>
        t.tagName.toLowerCase() === "button" &&
        t.dataset.class === "btnMakerModel"
    );
    // on focus list
    if (t) {
      setModelUlToSmYear(t);
    } else {
      // on focus other and exist selected maker
      const t = getSelectedListOfModelBySmMakerModel();
      if (t) {
        setModelUlToSmYear(t);
      }
    }
  }
  function onBlur() {
    initModelUlToSmYear();
    const t = getSelectedListOfModelBySmMakerModel();
    if (t) {
      setModelUlToSmYear(t);
    }
  }
  function onClickWording(event) {
    const t = getEventTargetsByBetween(event).find(
      (t) =>
        t.id === "btnWordingToEng" ||
        t.id === "btnWordingToJpn" ||
        t.id === "btnWordingToEngNavi" ||
        t.id === "btnWordingToJpnNavi"
    );
    if (!t) return null;

    setLanguageTypeToDataset(t);
    setColorToBtnWording(t);
    setWording();
    setWordingAfter();
  }
}

export async function onRotationDisplay(event) {
  const agl = window.screen.orientation.angle;
  if (agl === 90 || agl === 270) {
    // ポートレイト > ランドスケープ）
    changeWindowByFlexOrBlock("full");
  } else if (agl === 0 || agl === 180) {
    // ランドスケープ > ポートレイト
    changeWindowByFlexOrBlock("block");
  }
}
